import { ReactElement } from 'react';
import { IFragmentTextCalloutFields } from 'types/contentful';
import Image from './Image';
import Link from './Link';

interface CalloutProps extends IFragmentTextCalloutFields {
  index: number;
}

export default function Callout({ heading, copy, link, icon, index }: CalloutProps): ReactElement {
  const hydratedHeading = link ? (
    <Link slug={link}>
      <a>
        <h2 className="text-lg font-heading font-bold my-3">{heading}</h2>
      </a>
    </Link>
  ) : (
    <h2 className="text-lg font-heading font-bold my-3">{heading}</h2>
  );

  return (
    <div className="flex flex-col w-full md:w-1/3">
      {icon && (
        <Image
          image={icon}
          query="w=47&h=47"
          classNames="hidden md:block md:ml-10 w-calloutIcon h-calloutIcon"
        />
      )}
      <div
        className={`flex flex-col flex-auto md:pl-10 md:pr-16 mt-4 md:pb-4 pt-5 md:pt-0 ${
          index > 0 ? 'md:border-l border-t md:border-t-0 border-black' : ''
        }`}
      >
        {hydratedHeading}
        <p className="text-sm leading-6">{copy}</p>
      </div>
    </div>
  );
}
