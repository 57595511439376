import { ReactElement } from 'react';
import Callout from 'components/UI/Callout';
import { IComponentThreeTextCalloutFields } from 'types/contentful';

export default function TextCallouts({ callouts }: IComponentThreeTextCalloutFields): ReactElement {
  return (
    <div className="px-4 py-6 lg:px-20 md:py-12 md:bg-neutral flex flex-col md:flex-row">
      {callouts.map((callout, i) => (
        <Callout key={i} index={i} {...callout.fields} />
      ))}
    </div>
  );
}
